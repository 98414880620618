import router from "@/router";
import { deleteApi, stringifyParams } from "@/utils/apis";
import type { AdminToken, DateTime } from "@/definitions/types";
import dayjs from "dayjs";
import jwt_decode from "jwt-decode";
import { useAdminStore } from "@/stores/admin";
import { API_HOST } from "@/constants/envs";
import axios from "axios";
import { useAlertStore } from "@/stores/alert";
import { parse } from "qs";

function formatPath(val: string): string {
  const _path = val.split("?");
  const uri = _path.splice(0, 1);
  return uri + stringifyParams(parse(_path.join("?")));
}

export async function routerPush(path: string): Promise<void> {
  if (formatPath(router.currentRoute.fullPath) === formatPath(path)) {
    return;
  }

  await router.push(path);
}
export async function routerReplace(path: string): Promise<void> {
  if (formatPath(router.currentRoute.fullPath) === formatPath(path)) {
    return;
  }

  await router.replace(path);
}

export async function goToMain(): Promise<void> {
  await routerPush("/");
}

export async function goSignInPage(): Promise<void> {
  const { clearAdmin } = useAdminStore();
  clearAdmin();
  await routerReplace("/sign-in");
}

export async function getNewToken(): Promise<
  | {
      accessToken: string;
      refreshToken: string;
    }
  | undefined
> {
  try {
    const response = await axios
      .create({
        baseURL: API_HOST,
        headers: {
          contentType: "application/json",
          AuthorizationR: await getValidatedRefreshToken(),
        },
      })
      .get<AdminToken>("api/v1/admins/token/refresh");
    return response.data;
  } catch (e: unknown) {
    if (axios.isAxiosError(e)) {
      const statusCode = e.response?.status || 500;
      if (statusCode === 401 || e.message === "Invalid token specified!") {
        await goSignInPage();
      } else if ([403, 404, 500].includes(statusCode)) {
        const { toastError } = useAlertStore();
        toastError(e.message);
      } else {
        const { toastError } = useAlertStore();
        console.warn(`Missing Status Code: ${statusCode}`);
        toastError(e.message);
      }
    } else {
      console.error(e);
    }
  }
}

export async function signOut(): Promise<void> {
  try {
    await deleteApi("v1/admins/sign-out", false);
  } catch (e) {
    console.error(e);
  }
  await goSignInPage();
}

export async function getValidatedAccessToken(): Promise<string> {
  let accessToken = window.localStorage.getItem("accessToken");
  if (!accessToken) {
    await goSignInPage();
    return "";
  }

  try {
    if (
      dayjs((jwt_decode(accessToken) as { exp: number }).exp * 1000).isBefore(
        dayjs(),
      )
    ) {
      const { reIssueAccessToken } = useAdminStore();
      await reIssueAccessToken();
      accessToken = window.localStorage.getItem("accessToken");
    }
  } catch (e: unknown) {
    await signOut();
  }
  return accessToken ?? "";
}

export async function getValidatedRefreshToken(): Promise<string> {
  const refreshToken = window.localStorage.getItem("refreshToken");
  if (
    !refreshToken ||
    dayjs((jwt_decode(refreshToken) as { exp: number }).exp * 1000).isBefore(
      dayjs(),
    )
  ) {
    await goSignInPage();
  }
  return refreshToken ?? "";
}

export function greaterThanNow(time: DateTime): boolean {
  const now = dayjs();
  return dayjs(time).isAfter(now);
}
export function isValidMinuteTimeFormat(value: string): boolean {
  return value
    .split(":")
    .every((time, index) => +time >= 0 && +time < (index === 0 ? 24 : 60));
}
