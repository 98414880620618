import theme from "@/configs/theme";
import toolbar from "@/configs/toolbar";
import { PRODUCT_TITLE, PRODUCT_VERSION } from "@/constants/envs";

export default {
  // product display information
  product: {
    name: PRODUCT_TITLE,
    version: PRODUCT_VERSION,
  },

  // theme configs
  theme,

  // toolbar configs
  toolbar,
};
