export default {
  // global theme for the app
  globalTheme: "light", // light | dark

  // side menu theme, use global theme or custom
  menuTheme: "global", // global | light | dark

  // toolbar theme, use global theme or custom
  toolbarTheme: "global", // global | light | dark

  // show toolbar detached from top
  isToolbarDetached: false,

  // wrap pages content with a max-width
  isContentBoxed: false,

  // application is right to left
  isRTL: false,

  // dark theme colors
  dark: {
    background: "#05090c",
    surface: "#111b27",
    primary: "#1c195b",
    secondary: "#56bad9",
    accent: "#e27338",
    error: "#ff5252",
    info: "#2196f3",
    success: "#4caf50",
    warning: "#fb8c00",
  },

  // light theme colors
  light: {
    background: "#ffffff",
    surface: "#f2f5f8",
    primary: "#1c195b",
    secondary: "#56bad9",
    accent: "#e27338",
    error: "#ff5252",
    info: "#2196f3",
    success: "#4caf50",
    warning: "#fb8c00",
  },
};
