<template>
  <div class="d-flex flex-grow-1">
    <!-- Navigation -->
    <v-navigation-drawer
      v-model="drawer"
      app
      floating
      class="elevation-1"
      :right="$vuetify.rtl"
      light
    >
      <!-- Navigation menu info -->
      <template #prepend>
        <div class="pa-2">
          <img
            src="@/assets/images/illustrations/new_logo.png"
            class=""
            alt="logo"
            @click="goToMain"
          />
          <div
            class="text-overline grey--text"
            style="text-transform: none"
            v-text="PRODUCT_VERSION"
          />
        </div>
      </template>

      <!-- Navigation menu -->
      <NavMenu v-if="drawerList.length > 0" :drawers="drawerList" />
    </v-navigation-drawer>

    <!-- Toolbar -->
    <v-app-bar app :flat="false" light>
      <v-card class="flex-grow-1 d-flex pa-0 ma-0" :flat="true">
        <div class="d-flex flex-grow-1 align-center">
          <div class="d-flex flex-grow-1 align-center">
            <v-app-bar-nav-icon @click.stop="drawer = !drawer" />

            <v-spacer class="d-none d-lg-block" />

            <v-spacer class="d-block d-sm-none" />

            <ToolbarAdmin />

            <h4 class="secondary--text ml-1" v-text="name" />
          </div>
        </div>
      </v-card>
    </v-app-bar>

    <v-main>
      <v-container class="fill-height pa-0 pt-6 pb-6 pl-4 pr-4" :fluid="true">
        <v-layout>
          <slot />
          <input type="text" style="width: 0; height: 0" />
        </v-layout>
      </v-container>
    </v-main>
  </div>
</template>

<script setup lang="ts">
import NavMenu from "@/components/navigation/NavMenu.vue";
import { computed, ref } from "vue";
import { storeToRefs } from "pinia";
import { useAdminStore } from "@/stores/admin";
import type { Drawer } from "@/definitions/types";
import { MenuType } from "@/definitions/selections";
import ToolbarAdmin from "@/components/toolbar/ToolbarAdmin.vue";
import { PRODUCT_VERSION } from "@/constants/envs";
import { goToMain } from "@/utils/commands";

const { name } = storeToRefs(useAdminStore());

const drawer = ref(true);

const drawerList = computed<Drawer[]>(() => {
  const result: Drawer[] = [];

  result.push({
    id: 1,
    name: "회원",
    type: MenuType.GROUP,
    icon: "mdi-account-outline",
    url: null,
    children: [
      {
        id: 11,
        name: "회원 대기",
        type: MenuType.PAGE,
        icon: null,
        url: "/management/waiting-user",
        children: null,
      },
      {
        id: 12,
        name: "회원 목록",
        type: MenuType.PAGE,
        icon: null,
        url: "/management/user",
        children: null,
      },
    ],
  });

  result.push({
    id: 2,
    name: "관리자",
    type: MenuType.GROUP,
    icon: "mdi-shield-account-outline",
    url: null,
    children: [
      {
        id: 21,
        name: "관리자 목록",
        type: MenuType.PAGE,
        icon: null,
        url: "/management/admin",
        children: null,
      },
    ],
  });

  result.push({
    id: 3,
    name: "게시판",
    type: MenuType.GROUP,
    icon: "mdi-bulletin-board",
    url: null,
    children: [
      {
        id: 31,
        name: "공지사항",
        type: MenuType.PAGE,
        icon: null,
        url: "/board/notice",
        children: null,
      },
      {
        id: 32,
        name: "지식공유",
        type: MenuType.PAGE,
        icon: null,
        url: "/board/knowledge-sharing",
        children: null,
      },
      {
        id: 33,
        name: "뉴스 룸",
        type: MenuType.PAGE,
        icon: null,
        url: "/board/news-room",
        children: null,
      },
      {
        id: 34,
        name: "F A Q",
        type: MenuType.PAGE,
        icon: null,
        url: "/board/faq",
        children: null,
      },
    ],
  });
  return result;
});
</script>
