<template>
  <v-app>
    <input type="text" style="width: 0; height: 0" />
    <component :is="currentLayout" v-if="isRouterLoaded">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </component>
    <GlobalSnackbarAlert />
    <GlobalDialogConfirm />
    <input type="text" style="width: 0; height: 0" />
  </v-app>
</template>

<script setup lang="ts">
import AuthLayout from "@/layouts/AuthLayout.vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import ErrorLayout from "@/layouts/ErrorLayout.vue";
import { computed, onBeforeMount, onMounted } from "vue";
import GlobalDialogConfirm from "@/views/components/dialog/GlobalDialogConfirm.vue";
import GlobalSnackbarAlert from "@/views/components/dialog/GlobalSnackbarAlert.vue";
import { useRoute } from "vue-router/composables";
import { PRODUCT_TITLE } from "@/constants/envs";
import {
  getValidatedAccessToken,
  getValidatedRefreshToken,
} from "@/utils/commands";

const layouts = {
  default: DefaultLayout,
  auth: AuthLayout,
  error: ErrorLayout,
};

const route = useRoute();
const isRouterLoaded = computed((): boolean => route.name !== null);
const currentLayout = computed(() => layouts[route.meta?.layout || "default"]);

onBeforeMount(async () => {
  await getValidatedRefreshToken();
  await getValidatedAccessToken();
});

onMounted(() => {
  document.title = PRODUCT_TITLE;
});
</script>
