<template>
  <v-list
    class="pa-0"
    style="position: fixed; bottom: 0; background: transparent; z-index: 999"
  >
    <v-list-item
      v-for="item in items"
      :key="item.uuid"
      style="background: transparent; box-shadow: none"
    >
      <SnackbarAlert :value="item" style="position: absolute" />
    </v-list-item>
  </v-list>
</template>

<script setup lang="ts">
import { useAlertStore } from "@/stores/alert";
import { storeToRefs } from "pinia";
import SnackbarAlert from "@/views/components/dialog/SnackbarAlert.vue";

const { items } = storeToRefs(useAlertStore());
</script>
