import { defineStore } from "pinia";
import jwt_decode from "jwt-decode";
import { getNewToken, signOut } from "@/utils/commands";
import type { LoggedInAdmin } from "@/definitions/types";
import { AdminRoleType } from "@/definitions/roles";
import type { AdminRoleTypeString } from "@/definitions/roles";

export const useAdminStore = defineStore("admin", {
  state: (): LoggedInAdmin => {
    return {
      id: 0,
      loginId: "",
      name: "",
      accessToken: "",
      role: null,
    };
  },
  getters: {
    loggedIn: (state: LoggedInAdmin): boolean =>
      !!state.id &&
      !!state.accessToken &&
      !!window.localStorage.getItem("accessToken") &&
      !!window.localStorage.getItem("refreshToken"),
    admin: (state: LoggedInAdmin): LoggedInAdmin => {
      return {
        id: state.id,
        loginId: state.loginId,
        name: state.name,
        accessToken: state.accessToken,
        role: state.role,
      };
    },
    hasAdminRole: (state: LoggedInAdmin): boolean => {
      return state.role === AdminRoleType.ADMIN_BASIC;
    },
  },
  actions: {
    clearAdmin(): void {
      window.localStorage.removeItem("accessToken");
      window.localStorage.removeItem("refreshToken");
      this.$reset();
    },
    async reIssueAccessToken(): Promise<void> {
      const newToken = await getNewToken();
      if (newToken) {
        this.saveToken(newToken);
      }
    },
    saveToken(payload: { accessToken: string; refreshToken: string }): void {
      try {
        const jwt = jwt_decode<{
          exp: number;
          id: number;
          loginId: string;
          name: string;
          role: AdminRoleTypeString;
        }>(payload.accessToken);
        window.localStorage.setItem("accessToken", payload.accessToken);
        window.localStorage.setItem("refreshToken", payload.refreshToken);
        this.id = jwt.id;
        this.loginId = jwt.loginId;
        this.name = jwt.name;
        this.role = jwt.role;
        this.accessToken = payload.accessToken;
      } catch (e: unknown) {
        console.error(e);
        signOut().then();
      }
    },
  },
  persist: true,
});
