import type { SelectItem } from "@/definitions/types";
import { AdminRoleType, type AdminRoleTypeString } from "@/definitions/roles";

export const MenuType = {
  GROUP: "GROUP",
  PAGE: "PAGE",
  NEW_TAB: "NEW_TAB",
} as const;
export type MenuTypesString = (typeof MenuType)[keyof typeof MenuType];
export const BOOLEAN_TYPE: SelectItem<boolean>[] = [
  { value: true, text: "예" },
  { value: false, text: "아니요" },
];
export const CheckboxStatus = {
  TRUE: "TRUE",
  FALSE: "FALSE",
  INTERMEDIATE: "INTERMEDIATE",
} as const;

export const ADMIN_ROLE_TYPE: SelectItem<AdminRoleTypeString>[] = [
  { value: AdminRoleType.ADMIN_BASIC, text: "관리자(기본)" },
];
export const CountryType = {
  KOREA: "KR",
  CHINA: "CN",
} as const;

export type CountryTypeString = (typeof CountryType)[keyof typeof CountryType];

export const COUNTRY_TYPE: SelectItem<CountryTypeString>[] = [
  { value: CountryType.KOREA, text: "KR" },
  { value: CountryType.CHINA, text: "CN" },
];

export const LanguageType = {
  ENGLISH: "en",
  KOREAN: "ko",
  CHINESE: "zh-chs",
  GERMANY: "de",
  JAPANESE: "ja",
  FRENCH: "fr",
  ITALY: "it",
} as const;

export type LanguageTypeString =
  (typeof LanguageType)[keyof typeof LanguageType];

export const LANGUAGE_TYPE: SelectItem<LanguageTypeString>[] = [
  { value: LanguageType.ENGLISH, text: "English" },
  { value: LanguageType.KOREAN, text: "한국어" },
  { value: LanguageType.CHINESE, text: "中文" },
  { value: LanguageType.GERMANY, text: "Deutsch" },
  { value: LanguageType.JAPANESE, text: "日本語" },
  { value: LanguageType.FRENCH, text: "Francais" },
  { value: LanguageType.ITALY, text: "Italian" },
];

export const BOARD_LANGUAGE_SELECT_ITEMS: SelectItem<LanguageTypeString>[] = [
  { value: LanguageType.KOREAN, text: "한국어" },
  { value: LanguageType.CHINESE, text: "中文" },
];
export const WaitingUserStatusType = {
  DISAPPROVAL: "DISAPPROVAL",
  APPROVAL: "APPROVAL",
  REJECTION: "REJECTION",
  END: "END",
} as const;

export type WaitingUserStatusTypeString =
  (typeof WaitingUserStatusType)[keyof typeof WaitingUserStatusType];

export const WAITING_USER_STATUS_TYPE: SelectItem<WaitingUserStatusTypeString>[] =
  [
    { value: WaitingUserStatusType.DISAPPROVAL, text: "미승인" },
    { value: WaitingUserStatusType.APPROVAL, text: "가입 승인" },
    { value: WaitingUserStatusType.REJECTION, text: "반려" },
    { value: WaitingUserStatusType.END, text: "종료" },
  ];

export const AclType = {
  PRIVATE: "private",
  PUBLIC: "public-read",
};

export type AclTypeString = (typeof AclType)[keyof typeof AclType];

export const ProjectType = {
  AI_PRIX: "AI_PRIX",
  AIF: "aif",
};

export type ProjectTypeString = (typeof ProjectType)[keyof typeof ProjectType];

export const CategoryType = {
  general: "general",
  quotation: "quotation",
  booking: "booking",
  germanyFaq: "germany-faq",
  italyFaq: "italy-faq",
  franceFaq: "france-faq",
  netherlandsFaq: "netherlands-faq",
  nordicFaq: "nordic-faq",
};
export type CategoryTypeString =
  (typeof CategoryType)[keyof typeof CategoryType];
export const CATEGORY_SELECT_ITEMS: SelectItem<CategoryTypeString>[] = [
  { value: CategoryType.general, text: "General" },
  { value: CategoryType.quotation, text: "Quotation" },
  { value: CategoryType.booking, text: "Booking" },
  { value: CategoryType.germanyFaq, text: "Germany" },
  { value: CategoryType.nordicFaq, text: "Nordic" },
  { value: CategoryType.franceFaq, text: "France" },
  { value: CategoryType.italyFaq, text: "Italy" },
  { value: CategoryType.netherlandsFaq, text: "Netherlands" },
];
