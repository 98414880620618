<template>
  <v-snackbar
    :value="true"
    left
    light
    class="ma-4"
    :timeout="value.timer"
    @input="toastClose(value)"
  >
    <div class="d-flex align-center">
      <v-icon :color="value.color">{{ value.icon }}</v-icon>
      <!-- eslint-disable vue/no-v-html -->
      <span class="mx-2 alert-message" v-html="value.message" />
      <v-spacer />
      <span
        class="alert-close"
        :style="{ color: value.color, minWidth: '28px' }"
        @click="toastClose(value)"
      >
        닫기
      </span>
    </div>
  </v-snackbar>
</template>

<script setup lang="ts">
import { useAlertStore } from "@/stores/alert";
import type { Alert } from "@/definitions/types";

defineProps<{
  value: Alert;
}>();

const { toastClose } = useAlertStore();
</script>

<style scoped>
.alert-message {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0;
  text-align: left;
}

.alert-close {
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0;
  text-align: left;
  cursor: pointer;
}
</style>
